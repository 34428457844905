var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classContainer, attrs: { id: "page-container" } },
    [
      _vm.$store.state.layout.sidebar
        ? _c(
            "base-sidebar",
            { class: _vm.layoutClasses.sidebar },
            [_vm._t("sidebar")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.layout.header
        ? _c(
            "base-header",
            { class: _vm.layoutClasses.header },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "main-container" } },
        [
          _c("vue-confirm-dialog", {
            staticClass: "custom-vue-confirm-dialog"
          }),
          _vm._v(" "),
          _vm._t("content"),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "shortcut-top-bottom d-flex flex-column" },
            [
              _c("b-icon-arrow-up-square-fill", {
                staticClass: "shortcut-top-bottom-btn mb-1",
                staticStyle: { visibility: "hidden" },
                attrs: { id: "shortcut-top-bottom-btn-top" },
                on: {
                  click: function($event) {
                    return _vm.scrollTo("top")
                  }
                }
              }),
              _vm._v(" "),
              _c("b-icon-arrow-down-square-fill", {
                staticClass: "shortcut-top-bottom-btn",
                attrs: { id: "shortcut-top-bottom-btn-bottom" },
                on: {
                  click: function($event) {
                    return _vm.scrollTo("bottom")
                  }
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }