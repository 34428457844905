<template>
  <!-- Header -->
  <header id="page-header" style="background-color: #EEF8FF; box-shadow: none">
    <slot>
      <!-- Header Content -->
      <div class="content-header" style="width: unset">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Toggle Sidebar -->
          <base-layout-modifier
            action="sidebarToggle"
            size="sm"
            variant="dual"
            class="mr-2 d-lg-none"
          >
            <i class="fa fa-fw fa-bars"></i>
          </base-layout-modifier>

          <span class="mr-2 d-lg-none">
            <img
              style="height: 40px"
              src="@/assets/icons/logo-min.svg"
              @click="$router.push({ name: 'Dashboard' })"
              class="cursor-pointer"
            />
            &nbsp;&nbsp;
            <AddToHomeScreen />
          </span>

          <!-- END Toggle Sidebar -->

          <!-- Toggle Mini Sidebar -->
          <base-layout-modifier
            action="sidebarMiniToggle"
            size="sm"
            variant="light"
            class="mr-2 d-none d-lg-inline-block"
          >
            <i class="icon-arrow-2 cip-main-color"></i>
          </base-layout-modifier>
          <!-- END Toggle Mini Sidebar -->
        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div class="d-flex align-items-center">
          <!-- User Dropdown -->

          <b-button
            size="sm"
            variant="light"
            class="d-inline-block ml-2"
            @click="switchDarkMode"
          >
            <div class="tnt-icon">
              <i
                v-if="$store.state.app.darkmode"
                class="tnt-icon icon-light"
              ></i>
              <i v-else class="tnt-icon icon-dark cip-main-color"></i>
            </div>
          </b-button>

          <!-- Notifications Dropdown -->
          <b-dropdown
            size="sm"
            variant="light"
            class="d-inline-block ml-2"
            menu-class="dropdown-menu-lg p-0 border-0 font-size-sm"
            right
            no-caret
          >
            <template #button-content>
              <i
                class="tnt-icon icon-notif cip-main-color"
                :class="notifications.length ? 'has-notif' : ''"
              ></i>
            </template>
            <li>
              <div class="p-2">
                <span
                  class="font-common"
                  style="font-weight: bold; font-size: 12px"
                >
                  Notifikasi
                </span>
              </div>
              <ul class="nav-items mb-0" style="max-width: 100vw; max-height: 300px; overflow: auto">
                <li
                  v-for="(notification, index) in notifications"
                  :key="`notification-${index}`"
                  class="border-bottom"
                >
                  <div
                    class="d-flex px-2 py-1"
                    style="justify-content: space-between; cursor: pointer"
                    :style="getColor(notification.n_read)"
                    @click="actionClickNotif(notification)"
                  >
                    <div class="">
                      <div
                        class="font-common"
                        style="font-weight: bold; font-size: 12px"
                      >
                        {{ notification.n_title }}
                        <span
                          class="font-common ml-2"
                          style="font-size: 10px; color: #cccccc"
                        >
                          {{ notification.created_at }}
                        </span>
                      </div>
                      <div
                        class="font-common"
                        style="font-size: 12px"
                        v-html="notification.n_content"
                      ></div>
                    </div>
                  </div>
                </li>

                <li v-if="!notifications.length" class="p-2">
                  <!-- <b-alert variant="warning" class="text-center m-0" show> -->
                  <div class="text-center">
                    <p class="mb-0 font-common" style="font-size: 12px">
                      Tidak Ada Notifikasi
                    </p>
                  </div>
                  <!-- </b-alert> -->
                </li>
              </ul>
              <div v-if="notifications.length" class="p-2 border-top">
                <div class="text-center py-1">
                  <span
                    class="font-common"
                    style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #008eb7;
                      cursor: pointer;
                    "
                    @click="toNotifikasi"
                  >
                    Lihat Selengkapnya
                  </span>
                </div>
              </div>
            </li>
          </b-dropdown>
          <!-- END Notifications Dropdown -->

          <b-dropdown
            size="sm"
            variant="light"
            class="d-inline-block ml-2 cip-main-color"
            menu-class="p-0 font-size-sm"
            right
          >
            <template #button-content>
              <span class="cip-main-color">
                {{ name }}
                <!-- <b-avatar
                  badge-variant="primary"
                  :src="usrPhoto"
                  style="width: 28px; height: 28px"
                /> -->
              </span>
            </template>

            <b-dropdown-item href="#" @click="pengaturan">
              <i class="icon-user mr-2"></i>
              Akun
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="logout">
              <i class="icon-logout mr-2"></i>
              Keluar
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->
    </slot>
  </header>
  <!-- END Header -->
</template>

<script>
import AddToHomeScreen from "@/components/AddToHomeBtn";

export default {
  name: "BaseHeader",
  props: {
    classes: String
  },
  components: { AddToHomeScreen },
  data: () => ({
    name: JSON.parse(localStorage.getItem("user-info")).em_fname,

    baseSearchTerm: "",
    notifications: [],

    usrPhoto: null
  }),

  created() {
    this.initialize();
    this.getNotifikasi();
  },

  methods: {
    initialize() {
      this.$axios
        .post("/v1/user/init", {}, this.gl_req_header())
        .then(res => {
          if (res.status === 200) {
            if (res.data.data.em_photo !== null) {
              this.usrPhoto = res.data.data.em_photo.url_thumb;
            }
          }
        })
        .catch(e => {
          this.notifToast("danger", e);
        });
    },

    switchDarkMode() {
      this.gl_switchDarkMode();
      this.$store.state.app.darkmode = !this.$store.state.app.darkmode;
    },

    getNotifikasi() {
      this.$axios
        .post(
          "/v1/general/notification",
          {
            page: 1
          },
          this.gl_req_header()
        )
        .then(res => {
          if (res.status === 200) {
            // console.log(res.data.data)
            this.notifications = res.data.data.notification.list;
          }
        })
        .catch(e => {
          this.notifToast("danger", this.gl_catch_err(e));
        });
    },

    actionClickNotif(v) {
      this.$axios
        .post(
          "/v1/general/notification/update-notif",
          {
            n_hash: v.n_hash
          },
          this.gl_req_header()
        )
        .then(res => {
          if (res.status === 200) {
            // this.notifToast("success", res.data.message);
            this.getNotifikasi();
          }
        })
        .catch(e => {
          this.notifToast("danger", this.gl_catch_err(e));
        });

      if(v.n_link != null){
        window.location.replace(v.n_link);
      }

    },

    getColor(read) {
      let clr = "background: rgba(0, 142, 183, 0.1)";
      if (read) clr = "background: #FFFFFF";

      return clr;
    },

    onSubmit() {
      this.$router.push("/backend/pages/generic/search?" + this.baseSearchTerm);
    },

    eventHeaderSearch(event) {
      // When ESCAPE key is hit close the header search section
      if (event.which === 27) {
        event.preventDefault();
        this.$store.commit("headerSearch", { mode: "off" });
      }
    },

    pengaturan() {
      this.$router.push({ name: "Settings" });
    },

    toNotifikasi() {
      this.$router.push({ name: "Notifikasi" });
    },

    async logout() {
      await this.$axios
        .post("/v1/logout", {}, this.gl_req_header())
        .then(res => {
          if (res.status === 200) {
            // console.log(res)
            localStorage.clear();
            this.$router.push({ name: "Login" });
          }
        })
        .catch(e => {
          this.notifToast("danger", this.gl_catch_err(e));
        });
    }
  },

  mounted() {
    document.addEventListener("keydown", this.eventHeaderSearch);
  },

  destroyed() {
    document.removeEventListener("keydown", this.eventHeaderSearch);
  }
};
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #808080;
}
</style>
