export default {
  main: [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: "icon-dashboard",
      permission: "ALL",
      notifkey: null
    },

    {
      name: "Kehadiran",
      to: "/attendance",
      key: "attendance",
      icon: "icon-kehadiran",
      permission: "USER_KEHADIRAN",
      notifkey: null
    },

    {
      name: "Kehadiran Ditempat",
      to: "/onsite",
      key: "onsite",
      icon: "icon-onsite",
      permission: "KEHADIRAN_ONSITE",
      notifkey: null
    },

    {
      name: "Cuti",
      to: "/timeoff",
      icon: "icon-cuti",
      permission: "USER_CUTI",
      notifkey: null
    },

    // {
    //   name: "Reimburse",
    //   to: "/reimburse",
    //   key: "reimburse",
    //   icon: "icon-reimburse",
    //   permission: "USER_REIMBURSE",
    //   notifkey: "MENU_REIMBURSE"
    // },

    // {
    //   name: "Cash Advance",
    //   to: "/cashadvance",
    //   key: "cashadvance",
    //   icon: "icon-cash-advance",
    //   permission: "USER_CASH_ADVANCE",
    //   notifkey: "MENU_CASH_ADVANCE"
    // },

    {
      name: "Surat Tugas",
      to: "/surattugas",
      key: "surattugas",
      icon: "icon-surat-tugas",
      permission: "USER_SURAT_TUGAS",
      notifkey: "MENU_LETTER"
    },
    {
      name: "Approval",
      to: "/approval",
      icon: "icon-approval",
      permission: "USER_APPROVAL",
      notifkey: "MENU_APPROVAL"
    },

    {
      name: "Produksi",
      heading: true
    },

    {
      name: "Perencanaan Produksi",
      to: "/produksi/planning",
      icon: "icon-planning",
      permission: "PRODUKSI_PERENCANAAN",
      notifkey: null
    },
    {
      name: "Prod. Harian",
      to: "/produksi/produksi",
      icon: "icon-produksi",
      permission: "PRODUKSI_HARIAN",
      notifkey: null
    },
    {
      name: "Detail Harian",
      to: "/produksi/produksiRecap",
      icon: "fas fa-chart-bar",
      permission: "PRODUKSI_RECAP",
      notifkey: null
    },
    {
      name: "Rekap Prod. Harian",
      to: "/produksi/produksiRecap2",
      icon: "fas fa-chart-bar",
      permission: "PRODUKSI_RECAP_2",
      notifkey: null
    },
    {
      name: "Quality Control",
      to: "/produksi/qc",
      icon: "icon-quality-control",
      permission: "PRODUKSI_QC",
      notifkey: null
    },
    {
      name: "Waste",
      to: "/produksi/waste",
      icon: "icon-waste",
      permission: "PRODUKSI_WASTE",
      notifkey: null
    },
    {
      name: "WIP",
      to: "/produksi/stok",
      icon: "icon-stock",
      permission: "PRODUKSI_WIP",
      notifkey: null
    },

    {
      name: "R. Over Production",
      to: "/report/over-production",
      icon: "icon-stock",
      permission: "R_OVER_PRODUCTION",
      notifkey: null
    },

    {
      name: "Keuangan",
      heading: true
    },

    {
      name: "Setting Upah",
      to: "/produksi/setupah",
      icon: "icon-setting-upah",
      permission: "PAYROLL_SETTING",
      notifkey: null
    },
    {
      name: "Daftar Upah",
      to: "/payroll/view",
      icon: "icon-daftar-upah",
      permission: "PAYROLL",
      notifkey: null
    },
    {
      name: "Ambil Upah",
      to: "/payroll/take",
      icon: "icon-daftar-upah",
      permission: "PAYROLL_TAKE",
      notifkey: null
    },
    {
      name: "Angsuran",
      to: "/angsuran",
      icon: "icon-daftar-upah",
      permission: "ANGSURAN",
      notifkey: null
    },
    {
      name: "Kas Kecil",
      to: "/company/pettycash/detail/kecil",
      icon: "icon-petty-cash",
      permission: "SETTING_PETTYCASH_VIEW",
      notifkey: null
    },
    {
      name: "Kas Besar",
      to: "/company/pettycash/detail/besar",
      icon: "icon-petty-cash",
      permission: "SETTING_PETTYCASH_VIEW_2",
      notifkey: null
    },
    {
      name: "Kas PKL",
      to: "/company/pettycash/detail/pkl",
      icon: "icon-petty-cash",
      permission: "SETTING_PETTYCASH_VIEW_3",
      notifkey: null
    },
    {
      name: "Anggaran",
      to: "/budgeting",
      icon: "icon-daftar-upah",
      permission: "ANGGARAN",
      notifkey: null
    },
    {
      name: "Daftar Transfer",
      to: "/budgetingRT/report-transfer",
      icon: "icon-daftar-upah",
      permission: "ANGGARAN",
      notifkey: null
    },
    
    {
      name: "Neraca",
      to: "/neraca",
      icon: "icon-petty-cash",
      permission: "NERACA",
      notifkey: null
    },
    // {
    //   name: "Finance CR",
    //   to: "/finance/reimburse",
    //   icon: "icon-finance-cr",
    //   permission: "ADM_FINANCE_REIMBURSE_VIEW",
    //   notifkey: "ADMIN_FINANCE_REIMBURSE"
    // },
    // {
    //   name: "Finance CA",
    //   to: "/finance/cashadvance",
    //   icon: "icon-finance-ca",
    //   permission: "ADM_FINANCE_CADVANCE_VIEW",
    //   notifkey: "ADMIN_FINANCE_CADVANCE"
    // },
    // {
    //   name: "Kasir Petty Cash",
    //   to: "/finance/kasir",
    //   icon: "icon-kasir-pt",
    //   permission: "ADM_CASHIER_PETTYCASH_VIEW",
    //   notifkey: "ADMIN_KASIR_PETTYCASH"
    // },
    // {
    //   name: "Payment Voucher",
    //   to: "/paymentvoucher",
    //   icon: "icon-payment-voucher",
    //   permission: "ADM_FINANCE_CADVANCE_VIEW",
    //   notifkey: "ADMIN_PAYMENT_VOUCHER"
    // },
    // {
    //   name: "TopUp Petty Cash",
    //   to: "/pettycash",
    //   icon: "icon-top-up",
    //   permission: "ADM_CASHIER_PETTYCASH_TOPUP_VIEW",
    //   notifkey: "ADMIN_TOPUP_PETTYCASH"
    // },

    // {
    //   name: "Daftar Bank",
    //   to: "/companybank",
    //   icon: "icon-bank",
    //   permission: "SETTING_COMPANY_BANK_VIEW",
    //   notifkey: null
    // },

    {
      name: "Personalia",
      heading: true
    },

    {
      name: "Divisi",
      to: "/information/division",
      icon: "icon-divisi",
      permission: "INFO_DIVISION_VIEW",
      notifkey: null
    },

    {
      name: "Pengguna",
      to: "/employee",
      icon: "icon-karyawan",
      permission: "SETTING_EMPLOYEE_VIEW",
      notifkey: null
    },
    {
      name: "Mutasi Pengguna",
      to: "/empmutation",
      icon: "icon-pindah",
      permission: "EMP_MUTATION",
      notifkey: null
    },
    {
      name: "Grup",
      to: "/produksi/group",
      icon: "icon-group",
      permission: "GROUP",
      notifkey: null
    },
    {
      name: "Mutasi Grup",
      to: "/group-mutation",
      icon: "icon-pindah",
      permission: "EMP_MUTATION_GROUP",
      notifkey: null
    },
    {
      name: "Laporan Kehadiran",
      to: "/attend/admin/report",
      icon: "icon-laporan-kehadiran",
      permission: "ATTENDANCE_REPORT_VIEW",
      notifkey: null
    },
    {
      name: "Kehadiran Harian",
      to: "/attend/admin/report2",
      icon: "icon-laporan-kehadiran",
      permission: "ATTENDANCE_REPORT_VIEW",
      notifkey: null
    },
    {
      name: "Pengaturan Kehadiran",
      to: "/attend/admin/setting",
      icon: "icon-pengaturan-kehadiran",
      permission: "ATTENDANCE_SETTING",
      notifkey: null
    },
    {
      name: "Daftar Surat Tugas",
      to: "/surattgs/manage",
      icon: "icon-daftar-surat-tugas",
      permission: "ASSIGNMENT_LETTER_VIEW",
      notifkey: null
    },
    {
      name: "Pengajuan Cuti",
      to: "/admin/timeoff/create",
      icon: "icon-laporan-kehadiran",
      permission: "CUTI_MANAGE_CREATE",
      notifkey: null
    },
    {
      name: "Daftar Cuti",
      to: "/admin/timeoff/manage",
      icon: "icon-laporan-kehadiran",
      permission: "CUTI_MANAGE",
      notifkey: null
    },
    {
      name: "Report Fake GPS",
      to: "/admin/report/fakeGPS",
      icon: "icon-laporan-kehadiran",
      permission: "REPORT_FAKE_GPS",
      notifkey: null
    },
    {
      name: "Informasi",
      heading: true
    },
    // {
    //   name: "Perusahaan",
    //   to: "/information/company",
    //   icon: "icon-perusahaan",
    //   permission: "INFO_COMPANY_VIEW",
    //   notifkey: null
    // },
    // {
    //   name: "Kategori",
    //   to: "/information/category",
    //   icon: "icon-kategori",
    //   permission: "USER_KATEGORI",
    //   notifkey: null
    // },
    {
      name: "Kontak Pengguna",
      to: "/information/contactnumber",
      icon: "icon-kontak-karyawan",
      permission: "USER_KONTAK_KARYAWAN",
      notifkey: null
    },
    {
      name: "Pengumuman",
      to: "/announcement",
      icon: "icon-pengumuman",
      permission: "USER_PENGUMUMAN",
      notifkey: null
    },
    {
      name: "Kalender",
      to: "/information/calendar",
      icon: "icon-kalender",
      permission: "USER_KALENDER",
      notifkey: null
    },

    {
      name: "Pengaturan",
      heading: true
    },
    // {
    //   name: "Perusahaan PIC",
    //   to: "/company/managerial",
    //   icon: "icon-perusahan-pic",
    //   permission: "SETTING_MANAGERIAL_VIEW",
    //   notifkey: null
    // },
    // {
    //   name: "Kategori PIC",
    //   to: "/company/category",
    //   icon: "icon-kategori-pic",
    //   permission: "SETTING_CATEGORY_VIEW",
    //   notifkey: null
    // },
    {
      name: "Set Cuti",
      to: "/set-cuti",
      icon: "icon-kategori-pic",
      permission: "SET_CUTI"
    },
    {
      name: "Set Brand",
      to: "/set-brand",
      icon: "icon-kategori-pic",
      permission: "SET_BRAND"
    },
    // {
    //   name: "Set Variable",
    //   to: "/set-variable",
    //   icon: "icon-kategori-pic",
    //   permission: "SET_VARIABLE"
    // },
    {
      name: "Set Monthly Type",
      to: "/set-monthly-type",
      icon: "icon-kategori-pic",
      permission: "SET_MONTHLY_TYPE"
    },
    {
      name: "Set Tipe Pengguna",
      to: "/set-user-type",
      icon: "icon-kategori-pic",
      permission: "SET_USER_TYPE"
    },
    {
      name: "Set WIP Type",
      to: "/set-wip-type",
      icon: "icon-kategori-pic",
      permission: "SET_WIP_TYPE"
    }
  ]
};
