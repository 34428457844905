import Vue from "vue";
import router from "./router";

import App from "./App.vue";
import store from "./store";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
Vue.use(VueViewer);

import pdf from "vue-pdf";
Vue.component("pdf", pdf);

import vmodal from "vue-js-modal";
Vue.use(vmodal, { componentName: "cmodal" });

import VueConfirmDialog from "vue-confirm-dialog";
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import BaseLayoutModifier from "@/components/BaseLayoutModifier";
import BaseBlock from "@/components/BaseBlock";
import BaseBackground from "@/components/BaseBackground";
import BasePageHeading from "@/components/BasePageHeading";
import BaseNavigation from "@/components/BaseNavigation";

import clickRipple from "@/directives/clickRipple";
import toggleClass from "@/directives/toggleClass";
import "./registerServiceWorker";
import "@/directives/filters";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import "./assets/scss/tarantula/global-custom.scss";

import vueNumeralFilterInstaller from "vue-numeral-filter";
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import BackBtn from "@/views/mobile/components/BackBtn";
Vue.component(BackBtn.name, BackBtn);

import Breadcrumb from "@/views/mobile/components/Breadcrumb";
Vue.component(Breadcrumb.name, Breadcrumb);

// Axios
import axios from "axios";
const axiosConfig = {
  // Don't forget change on route also!
  // baseURL: 'https://apitnt.stagetrv.xyz/',
  baseURL: process.env.VUE_APP_API_URL
};
Vue.prototype.$axios = axios.create(axiosConfig);

// Register global components
Vue.component(BaseLayoutModifier.name, BaseLayoutModifier);
Vue.component(BaseBlock.name, BaseBlock);
Vue.component(BaseBackground.name, BaseBackground);
Vue.component(BasePageHeading.name, BasePageHeading);
Vue.component(BaseNavigation.name, BaseNavigation);

// Register global directives
Vue.directive("click-ripple", clickRipple);
Vue.directive("toggle-class", toggleClass);

// Disable tip shown in dev console when in development mode
Vue.config.productionTip = false;

Vue.mixin({
  data: function () {
    return {
      get currentStage() {
        //development
        //staging
        //production
        return process.env.VUE_APP_ENV;
      },
      // get currentVersion() {
      //   return process.env.VUE_APP_VERSION;
      // },
      get productionColor() {
        return "FFF";
      },
      get developmentColor() {
        return "ffbdb9";
      },
      get developmentSecondaryColor() {
        return "EEF8FF";
      },
      get stagingColor() {
        return "e7e7e7";
      },
      get userLoginxx() {
        return JSON.parse(localStorage.getItem("user-info"));
      }
    };
  },

  methods: {
    numberFormatter(args, convert_int = false, decimal_length = 4) {
      if (args == 0) {
        return 0;
      }

      // if decimal dari backend ada 4, maka di force untuk tampilin 4 decimal terakhir
      let res = String(args).split(".");
      let msd = 1;
      if (res.length > 1) {
        if (res[1].length >= decimal_length) {
          msd = decimal_length;
        }
      }

      let output = 0;
      if (convert_int) {

        // if(args < 0){
        //   output = parseInt(Math.floor(args));
        // }else{
          output = Math.round(args);
        // }
        // output = parseInt(args);
        output = new Intl.NumberFormat("id-ID").format(output);
      } else {
        output = new Intl.NumberFormat("id-ID", { minimumSignificantDigits: msd }).format(args);
      }
      return output;
    },

    thousandSeparator: function (args) {
      let extractStr = args.toString();
      let digitCounts = extractStr.length;
      if (digitCounts === 4) {
        let parts = {};
        parts[1] = extractStr.substr(0, 1);
        parts[2] = extractStr.substr(1, 4);
        return parts[1] + "." + parts[2];
      }
      if (digitCounts === 5) {
        let parts = {};
        parts[1] = extractStr.substr(0, 2);
        parts[2] = extractStr.substr(2, 5);
        return parts[1] + "." + parts[2];
      }
      if (digitCounts === 6) {
        let parts = {};
        parts[1] = extractStr.substr(0, 3);
        parts[2] = extractStr.substr(3, 6);
        return parts[1] + "." + parts[2];
      }
      if (digitCounts === 7) {
        let parts = {};
        parts[1] = extractStr.substr(0, 1);
        parts[2] = extractStr.substr(1, 3);
        parts[3] = extractStr.substr(4, 7);
        return parts[1] + "." + parts[2] + "." + parts[3];
      }
      if (digitCounts === 8) {
        let parts = {};
        parts[1] = extractStr.substr(0, 2);
        parts[2] = extractStr.substr(2, 3);
        parts[3] = extractStr.substr(5, 7);
        return parts[1] + "." + parts[2] + "." + parts[3];
      }
      if (digitCounts === 9) {
        let parts = {};
        parts[1] = extractStr.substr(0, 3);
        parts[2] = extractStr.substr(3, 3);
        parts[3] = extractStr.substr(6, 8);
        return parts[1] + "." + parts[2] + "." + parts[3];
      }
    },

    notifToast(variant, content, noAutoHide = false) {
      // variant: success, danger
      this.$bvToast.toast(content, {
        title: variant == "success" ? "Sukses" : "Terjadi Kesalahan",
        variant: variant,
        solid: true,
        noAutoHide: noAutoHide
      });
    },

    getFilterDesc(tglawal, tglakhir, status_map, status, keyword, brand) {
      const monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ];
      let stringStatus = "";
      if (status_map !== null) {
        status_map.map(function (el) {
          if (el.value == status) {
            stringStatus = el.text;
          }
        });
      }

      let filterDesc = "<i>Menampilkan data </i>";

      if (tglawal && tglakhir) {
        const tglawalParsed = new Date(Date.parse(tglawal));
        const tglAwalFormatted =
          tglawalParsed.getDate() +
          " " +
          monthArr[tglawalParsed.getMonth()] +
          " " +
          tglawalParsed.getFullYear();

        const tglAkhirParsed = new Date(Date.parse(tglakhir));
        const tglAkhirFormatted =
          tglAkhirParsed.getDate() +
          " " +
          monthArr[tglAkhirParsed.getMonth()] +
          " " +
          tglAkhirParsed.getFullYear();

        filterDesc +=
          "<i>tanggal </i>" +
          "<b>" +
          tglAwalFormatted +
          "</b>" +
          " <i>hingga</i> " +
          "<b>" +
          tglAkhirFormatted +
          "</b>";
      }

      if (status !== null) {
        if (stringStatus) {
          filterDesc += "<i>, status </i>" + "<b>" + stringStatus + "</b>";
        }
      }

      if (keyword !== null) {
        if (keyword) {
          filterDesc += "<i>, pencarian<i> " + "<b>" + keyword + "</b>";
        }
      }

      if (brand !== null) {
        if (brand) {
          filterDesc += "<i>, brand </i>" + "<b>" + brand + "</b>";
        }
      }

      return filterDesc;
    },

    getDefaultDate(minus = 0) {
      // let date = new Date();
      // date.setDate(date.getDate() - minus);
      // console.log('dateraw', date);
      // console.log('dateraw2', date.toISOString());
      // date = date.toISOString().split("T")[0];
      // console.log('date', date);
      // return date;

      let date2 = new Date();
      var tzoffset = (date2).getTimezoneOffset() * 60000 - (minus * 86400 * 1000 * -1); //offset in milliseconds
      // console.log('tzoffset', tzoffset);
      var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
      let datel = localISOTime.split("T")[0];
      // console.log('test', localISOTime);
      // console.log('datel', datel);
      
      return datel;
    },

    viewFile(data) {
      // console.log(data);
      if (data.file_type !== null) {
        let urls = [];
        let aa = "";
        if (data.file_path) {
          aa = data.file_path;
        } else if (data.url) {
          aa = data.url;
        }
        switch (data.file_type) {
          case "JPG":
          case "JPEG":
          case "PNG":
            urls.push(aa);
            this.$viewerApi({ images: urls });
            break;

          case "PDF":
            this.pdfName = data.file_name;
            this.pdfUrl = data.url;
            this.pdfShown = true;
            // console.log(data);
            break;

          // case "PDF":
          //   window.open(aa, "_blank");
          //   urls.push(aa);
          //   break;
        }
      } else {
        // let urls = [];
        // urls.push(aa);
        // this.$viewerApi({ images: urls });
      }
    },

    closePdf(data) {
      // console.log(data)
      this.pdfShown = data;
    },

    getMonthYear() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      const now = new Date();
      const now_month = now.getMonth();
      const now_year = now.getFullYear();
      let tmp_month = now_month;
      let tmp_year = now_year;

      //set bulan dropdown
      let monthList = [];
      for (let index = 1; index <= 13; index++) {
        monthList.push({
          value: tmp_year + "-" + ("0" + (tmp_month + 1)).slice(-2),
          text: monthNames[tmp_month] + " " + tmp_year
        });

        tmp_month = tmp_month - 1;
        if (tmp_month == -1) {
          tmp_month = 11;
          tmp_year = tmp_year - 1;
        }
      }

      let monthCurrent = now_year + "-" + ("0" + (now_month + 1)).slice(-2);

      let arr = [];
      arr["month_current"] = monthCurrent;
      arr["month_list"] = monthList;

      return arr;
    },

    gl_time_format(time, type = 1) {
      if (type == 1) {
        // console.log(time.substring(0, 5));
        return time.substring(0, 5);
      } else {
        return time;
      }
    },

    gl_req_header(type = "general", additional = []) {
      //how to use, when call kek gini ya
      //this.gl_req_header()
      //this.gl_req_header("media")
      //this.gl_req_header("general", [{aa: 'ok'},{ bb: 'ok'}])

      //default headers
      let config = {
        headers: {
          "x-api-key": localStorage.getItem("user-token")
        }
      };

      //if media
      if (type == "media") {
        let multipartHeader = { "Content-Type": "multipart/form-data" };
        config.headers = {
          ...config.headers,
          ...multipartHeader
        };
      }

      //push additional
      if (additional.length > 0) {
        additional.forEach(value => {
          config.headers = {
            ...config.headers,
            ...value
          };
        });
      }

      return config;
    },

    gl_catch_err(e) {
      // console.log('gl_catch_err on main.js -> ', e);
      if(process.env.NODE_ENV == 'staging' || process.env.NODE_ENV == 'development'){
        console.log('gl_catch_err on main.js -> ', e);
      }
      const err = e.response.data.error;

      if (typeof err === "string" || err instanceof String) {
        return err;
      } else if (typeof err === "object" && err !== null) {
        let msg = "";
        Object.values(err).map(aa => {
          msg = aa[0];
        });

        return msg;
      }

      return "Terjadi Kesalahan #NF424 FrontEnd";
    },

    gl_check_permission(key) {
      const userPermissions = JSON.parse(
        localStorage.getItem("user-permissions")
      );

      if (userPermissions.includes(key)) return true;

      return false;
    },

    gl_switchDarkMode() {
      if (localStorage.getItem("darkmode") == null) {
        localStorage.setItem("darkmode", "on");
        return;
      }

      if (localStorage.getItem("darkmode") === "on") {
        localStorage.setItem("darkmode", "off");
        return;
      }

      if (localStorage.getItem("darkmode") === "off") {
        localStorage.setItem("darkmode", "on");
        return;
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 45 && keyCode !== 46) {
        // 46 dot 
        // 45 minus
        // 44 comma
        $event.preventDefault();
      }
    },

    gl_get_prod_icon(status) {
      let icon = "archive-fill";
      let color = "text-success";
      let text = "Simpan";
      let image = "../img/icons/produksi_plan.svg";


      switch (status) {
        case "PLAN":
          icon = "pencil-fill";
          color = "text-success";
          text = "OPEN Produksi";
          image = "../img/icons/produksi_plan.svg";
          break;
        case "OPEN":
          icon = "gear-fill";
          color = "text-success";
          text = "CLOSE Produksi";
          image = "../img/icons/produksi_open.svg";
          break;
        case "CLOSE":
          icon = "clock-fill";
          color = "text-success";
          text = "Konfirmasi CHECK Produksi";
          image = "../img/icons/produksi_close.svg";
          break;
        case "CHECK":
          icon = "arrow-clockwise";
          color = "text-success";
          text = "Finalisasi Produksi";
          image = "../img/icons/produksi_check.svg";
          break;
        case "FINAL":
          icon = "check";
          color = "text-success";
          text = "";
          image = "../img/icons/produksi_final.svg";
          break;
      }

      let arr = { icon: icon, color: color, text: text, image: image };

      return arr;
    },

    gl_get_payroll_icon(status) {
      let icon = "archive-fill";
      let color = "text-success";

      switch (status) {
        case "DRAFT":
          icon = "pencil-fill";
          color = "text-success";
          break;
        case "SUBMIT":
          icon = "clock-fill";
          color = "text-success";
          break;
        case "VERIFIED":
          icon = "check-circle-fill";
          color = "text-success";
          break;
      }

      let arr = { icon: icon, color: color };

      return arr;
    },

    gl_get_user_type(key) {
      //key-nya
      // USER_BORONGAN: "Karyawan Borongan"
      // USER_BULANAN: "Karyawan Bulanan"
      // USER_BULANAN_SPC: "Karyawan Bulanan (Khusus)"
      // USER_HARIAN: "Karyawan Bulanan (Termin)"
      // USER_MAGANG: "Peserta Magang"

      if (localStorage.getItem('user-type') == null) {
        this.$axios
          .post("/v1/admin/setting/user-term", {}, this.gl_req_header())
          .then(res => {
            if (res.status === 200) {
              let tmpme = {};
              res.data.data.user_term.list.map((v) => {
                tmpme[v.id] = v.label
              });

              localStorage.setItem("user-type", JSON.stringify(tmpme));
            }
          })
          .catch(e => {
            this.notifToast("danger", e.response.data.error);
          });
      }

      let userTypeParsed = JSON.parse(localStorage.getItem("user-type"));
      if (userTypeParsed[key] == undefined) {
        return 'NotFound555';
      }
      return userTypeParsed[key];

    }
  }
});

// Craft new application
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
