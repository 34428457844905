var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "pt-3",
      style: _vm.getBgColor(),
      attrs: { id: "sidebar", "aria-label": "Main Navigation" }
    },
    [
      _vm._t("default", [
        _c(
          "div",
          { staticClass: "content-header justify-content-left" },
          [
            _c(
              "router-link",
              { staticClass: "font-w600 text-dual", attrs: { to: "/" } },
              [
                _c("img", {
                  attrs: {
                    src: require("../../assets/icons/logo-cipris.svg"),
                    width: "130px"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "d-lg-none ml-1",
                    attrs: {
                      size: "sm",
                      variant: "dual",
                      action: "sidebarClose"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-times" })]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "simplebar",
          { staticClass: "js-sidebar-scroll", style: _vm.getBgColor() },
          [
            _c("div", { staticClass: "d-flex p-2 mb-2 mx-4 mt-3 box-aa" }, [
              _c(
                "div",
                { staticClass: "mr-2" },
                [
                  _c("b-avatar", {
                    attrs: {
                      "badge-variant": "light",
                      src: _vm.usrPhoto,
                      size: "3rem"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto font-common" }, [
                _c("div", { staticClass: "font-weight-bold cip-main-color" }, [
                  _c(
                    "span",
                    {
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "Settings" })
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.userInfo.em_fname || "") +
                          " " +
                          _vm._s(_vm.userInfo.em_lname || "")
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("small", [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.userInfo.em_division_name +
                            " - " +
                            _vm.userInfo.em_position_name
                        ) +
                        "\n            "
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("UpdateAppBtn", {
                  staticClass: "w-100 mx-4",
                  attrs: { alwaysShow: "false", btnText: "Update Aplikasi" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-side pt-2" },
              [_c("base-navigation", { attrs: { nodes: _vm.navigation } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-right mt-2 mr-3 mb-3 text-dark" }, [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.toChangelog }
                },
                [_vm._v(" v" + _vm._s(_vm.ver) + " ")]
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }