import Vue from "vue";
import Router from "vue-router";

// Axios
import axios from "axios";

// Main layouts
import LayoutBackend from "@/layouts/variations/Backend.vue";
// import LayoutSimple from '@/layouts/variations/Simple.vue'

// Register Vue Router
Vue.use(Router);

// Router Configuration
const router = new Router({
  linkActiveClass: "active",
  linkExactActiveClass: "",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // Cipris Routes:
    // Login
    {
      path: "*",
      name: "NotFound",
      redirect: "/"
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/mobile/login/Login.vue")
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: () => import("../views/mobile/maintenance/maintenance.vue")
    },

    {
      path: "/s/:id?",
      name: "redirectWA",
      component: () => import("../views/mobile/others/ForwarderPage.vue")
    },

    {
      path: "/formInstan/:id?",
      name: "formInstanEmployee",
      component: () => import("../views/mobile/others/formInstan.vue")
    },

    {
      path: "/",
      name: "Home",
      redirect: "/dashboard",
      component: LayoutBackend,
      children: [
        // Dashboard
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue")
        },

        // Pengaturan
        {
          path: "settings",
          name: "Settings",
          component: () => import("@/views/mobile/pengaturan/Pengaturan.vue")
        },
        {
          path: "settings/profile",
          name: "SettingProfile",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanProfile.vue")
        },
        {
          path: "settings/company",
          name: "SettingCompany",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanCompany.vue")
        },
        {
          path: "settings/bank",
          name: "SettingBank",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanBank.vue")
        },
        {
          path: "settings/paycheck",
          name: "SettingPaycheck",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanPaycheck.vue")
        },
        {
          path: "settings/password",
          name: "SettingPassword",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanPassword.vue")
        },
        {
          path: "settings/bank-3",
          name: "SettingBank3",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanBankPihak3.vue")
        },
        {
          path: "/logindevices",
          name: "LoginDevices",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanLoginDevices.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Kehadiran
        ///////////////////////////////////////////////////////////
        {
          path: "attendance",
          name: "Attendance",
          component: () => import("../views/mobile/attendance/Attendance.vue")
        },

        {
          path: "attendance/picture",
          name: "AttendancePicture",
          component: () =>
            import("../views/mobile/attendance/AttendancePicture.vue")
        },

        {
          path: "attendance/form",
          name: "AttendanceForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceForm.vue")
        },

        {
          path: "attendance/history",
          name: "AttendanceHistory",
          component: () =>
            import("../views/mobile/attendance/AttendanceHistory.vue")
        },

        {
          path: "attendance/request/form",
          name: "AttendanceRequestForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceRequestForm.vue")
        },

        {
          path: "attend/admin/report", //path diganti karena menyebabkan bug sidebar active bersamaan
          name: "AttendanceReport",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReport.vue")
        },
        {
          path: "attend/admin/report2", //path diganti karena menyebabkan bug sidebar active bersamaan
          name: "AttendanceReport2",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReport2.vue")
        },
        {
          path: "attend/admin/setting",
          name: "AttendanceSetting",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceSetting.vue")
        },

        // Approval Attendance
        // {
        //   path: '/approval/attendace_request/:id?',
        //   name: 'ApprovalAttendanceRequest',
        //   component: () => import('../views/mobile/approval/ApprGlobal.vue')
        // },

        // Perpindahan Karyawan
        {
          path: "/empmutation",
          name: "EmployeeMutation",
          component: () => import("../views/mobile/mutasi/EmployeeMutation.vue")
        },
        {
          path: "/group-mutation",
          name: "EmployeeGroupMutation",
          component: () => import("../views/mobile/mutasi/EmployeeGroupMutation.vue")
        },

        // Approval Global
        {
          path: "/approval/:id",
          name: "ApprovalGlobal",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Kehadiran On Site
        ///////////////////////////////////////////////////////////
        {
          path: "onsite",
          name: "OnSiteAttendance",
          meta: { permission: "KEHADIRAN_ONSITE" },
          component: () => import("../views/mobile/onsite/Attendance.vue")
        },

        {
          path: "onsite/picture/",
          name: "OnSiteAttendancePicture",
          meta: { permission: "KEHADIRAN_ONSITE" },
          component: () =>
            import("../views/mobile/onsite/AttendancePicture.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Cuti
        ///////////////////////////////////////////////////////////
        {
          path: "timeoff",
          name: "Timeoff",
          component: () => import("../views/mobile/timeoff/Timeoff.vue")
        },

        {
          path: "timeoff/form",
          name: "TimeoffForm",
          component: () => import("../views/mobile/timeoff/TimeoffForm.vue")
        },

        {
          path: "admin/timeoff/create",
          name: "AdminTimeoffCreate",
          component: () => import("../views/mobile/timeoff/admin/TimeoffCreate.vue")
        },
        {
          path: "admin/timeoff/manage",
          name: "AdminTimeoffManage",
          component: () => import("../views/mobile/timeoff/admin/TimeOffManage.vue")
        },
        {
          path: "admin/report/fakeGPS",
          name: "AdminReportFakeGps",
          component: () => import("../views/mobile/attendance/admin/AttendanceReportFakeGps.vue")
        },


        // Approval TimeOff
        // {
        //   path: '/approval/timeoff/:id?',
        //   name: 'ApprovalTimeOff',
        //   component: () => import('../views/mobile/approval/ApprGlobal.vue')
        // },

        ///////////////////////////////////////////////////////////
        /// Reimburse
        ///////////////////////////////////////////////////////////
        {
          path: "reimburse",
          name: "Reimburse",
          component: () => import("../views/mobile/reimburse/ReimburseHome.vue")
        },
        {
          path: "reimburse/add/:id?",
          name: "ReimburseAdd",
          component: () =>
            import("../views/mobile/reimburse/ReimburseTambah.vue")
        },
        {
          path: "reimburse/detail/:id",
          name: "ReimburseDetail",
          component: () =>
            import("../views/mobile/reimburse/ReimburseDetail.vue")
        },
        {
          path: "reimburse/riwayat/:id?",
          name: "ReimburseHistory",
          component: () =>
            import("../views/mobile/reimburse/ReimburseRiwayat.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Approval
        ///////////////////////////////////////////////////////////
        {
          path: "approval",
          name: "Approval",
          component: () => import("../views/mobile/approval/Approval.vue")
        },
        {
          path: "approval/history",
          name: "ApprovalHistory",
          component: () =>
            import("../views/mobile/approval/ApprovalHistory.vue")
        },
        {
          path: "approval/:id?",
          name: "ApprovalReimburse",
          component: () => import("../views/mobile/approval/ApprReimburse.vue")
        },
        {
          path: "approval/topuppc/:id?",
          name: "ApprovalTopUpPettyCash",
          component: () =>
            import("../views/mobile/approval/ApprTopUpPettyCash.vue")
        },

        // Approval Cash Advance
        {
          path: "approval/pengajuan/:id?",
          name: "ApprovalCAPengajuan",
          component: () =>
            import("../views/mobile/approval/ApprCAPengajuan.vue")
        },
        {
          path: "approval/realisasi/:id?",
          name: "ApprovalCARealisasi",
          component: () =>
            import("../views/mobile/approval/ApprCARealisasi.vue")
        },

        // Cash Advance
        {
          path: "cashadvance",
          name: "CashAdvance",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceHome.vue")
        },
        {
          path: "cashadvance/add",
          name: "CashAdvanceAdd",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceTambah.vue")
        },
        {
          path: "cashadvance/history",
          name: "CashAdvanceHistory",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceRiwayat.vue")
        },
        {
          path: "cashadvance/detail/:id?",
          name: "CashAdvanceDetail",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceDetail.vue")
        },
        {
          path: "cashadvance/additional/:id?",
          name: "CashAdvanceAdditional",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceTambahPengajuan.vue")
        },
        {
          path: "cashadvance/realization/:id?",
          name: "CashAdvanceRealization",
          component: () =>
            import(
              "../views/mobile/cashadvance/CashAdvanceTambahPengeluaran.vue"
            )
        },
        {
          path: "cashadvance/realization/edit/:id?",
          name: "CashAdvanceEditRealization",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceEditPengeluaran.vue")
        },
        {
          path: "cashadvance/request/edit/:id?/:main?",
          name: "CashAdvanceEditRequest",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceEditPengajuan.vue")
        },

        // Produksi
        {
          path: "produksi/group",
          name: "ProduksiGroup",
          component: () => import("../views/mobile/group/ProduksiGroup.vue")
        },
        {
          path: "produksi/waste",
          name: "ProduksiWaste",
          component: () => import("../views/mobile/group/ProduksiWaste.vue")
        },
        {
          path: "produksi/qc",
          name: "ProduksiQC",
          component: () => import("../views/mobile/group/ProduksiQC.vue")
        },
        {
          path: "produksi/stok",
          name: "ProduksiStok",
          component: () => import("../views/mobile/group/ProduksiStok.vue")
        },
        {
          path: "produksi/planning",
          name: "ProduksiPlanning",
          meta: { permission: "PRODUKSI_PERENCANAAN" },
          component: () => import("../views/mobile/group/ProduksiPlanning.vue")
        },
        {
          path: "produksi/setupah",
          name: "ProduksiSettingUpah",
          meta: { permission: "PAYROLL_SETTING" },
          component: () => import("../views/mobile/group/ProduksiSetupah.vue")
        },
        {
          path: "produksi/produksi",
          name: "ProduksiProduksi",
          component: () => import("../views/mobile/group/ProduksiProduksi.vue")
        },
        {
          path: "produksi/produksiRecap",
          name: "ProduksiProduksiRecap",
          component: () => import("../views/mobile/group/ProduksiRecap.vue")
        },
        {
          path: "produksi/produksiRecap2",
          name: "ProduksiProduksiRecap2",
          component: () => import("../views/mobile/group/ProduksiRecap2.vue")
        },

        //Payroll
        {
          path: "payroll/view",
          name: "PayrollView",
          meta: { permission: "PAYROLL" },
          component: () => import("../views/mobile/payroll/view.vue")
        },
        {
          path: "payroll/edit",
          name: "PayrollEdit",
          meta: { permission: "PAYROLL_CREATE" },
          component: () => import("../views/mobile/payroll/edit.vue")
        },
        {
          path: "payroll/take",
          name: "PayrollTake",
          meta: { permission: "PAYROLL_TAKE" },
          component: () => import("../views/mobile/payroll/take.vue")
        },

        //Budgeting
        {
          path: "budgeting",
          name: "Budgeting",
          meta: { permission: "ANGGARAN" },
          component: () => import("../views/mobile/budgeting/view.vue")
        },
        {
          path: "budgeting/edit",
          name: "BudgetingEdit",
          meta: { permission: "ANGGARAN_CREATE" },
          component: () => import("../views/mobile/budgeting/edit.vue")
        },
        {
          path: "budgetingRT/report-transfer",
          name: "BudgetingReportTransfer",
          meta: { permission: "ANGGARAN" },
          component: () => import("../views/mobile/budgeting/reportTransfer.vue")
        },

        //Angsuran
        {
          path: "angsuran",
          name: "AngsuranView",
          meta: { permission: "ANGSURAN" },
          component: () => import("../views/mobile/angsuran/view.vue")
        },

        //Neraca
        {
          path: "neraca",
          name: "NeracaView",
          meta: { permission: "NERACA" },
          component: () => import("../views/mobile/neraca/view.vue")
        },
        {
          path: "neraca/detail",
          name: "NeracaDetail",
          meta: { permission: "NERACA" },
        component: () => import("../views/mobile/neraca/detail.vue")
        },

        // Finance
        // {
        //   path: "finance/reimburse",
        //   name: "FinanceReimburse",
        //   component: () =>
        //     import("../views/mobile/finance/ApprovalReimburse.vue")
        // },
        // {
        //   path: "finance/approval/:id",
        //   name: "FinanceApprovalReimburse",
        //   component: () =>
        //     import("../views/mobile/finance/ApprReimburseDetail.vue")
        // },
        {
          path: "finance/cashadvance",
          name: "FinanceCashAdvance",
          component: () =>
            import("../views/mobile/finance/ApprovalCashAdvance.vue")
        },
        {
          path: "finance/cashadvance/:id?",
          name: "FinanceApprovalCashAdvance",
          component: () =>
            import("../views/mobile/finance/ApprCashAdvanceDetail.vue")
        },

        // Kasir
        {
          path: "finance/kasir",
          name: "Kasir",
          component: () => import("../views/mobile/kasir/ApprovalReimburse.vue")
        },
        {
          path: "finance/kasir/approval/:id/:ispc?",
          name: "KasirApproval",
          component: () =>
            import("../views/mobile/kasir/ApprReimburseDetail.vue")
        },

        // Payment Voucher
        {
          path: "paymentvoucher",
          name: "PaymentVoucher",
          component: () =>
            import("../views/mobile/paymentvoucher/PaymentVoucher.vue")
        },
        {
          path: "paymentvoucher/detail/:id",
          name: "PaymentVoucherDetail",
          component: () =>
            import("../views/mobile/paymentvoucher/PaymentVoucherDetail.vue")
        },

        // Employee
        {
          path: "employee",
          name: "Employee",
          component: () => import("../views/mobile/karyawan/Employee.vue")
        },
        // {
        //   path: 'employee/add',
        //   name: 'EmployeeAdd',
        //   component: () => import('../views/mobile/karyawan/EmployeeAdd.vue')
        // },
        {
          path: "employee/form/:id?/:reactive?",
          name: "EmployeeForm",
          component: () => import("../views/mobile/karyawan/EmployeeForm.vue")
        },
        // {
        //   path: 'employee/edit/:id',
        //   name: 'EmployeeEdit',
        //   component: () => import('../views/mobile/karyawan/EmployeeChange.vue')
        // },

        // Information
        // {
        //   path: "information/company",
        //   name: "InformationCompany",
        //   component: () => import("../views/mobile/information/Company.vue")
        // },
        {
          path: "information/company/add",
          name: "CompanyAdd",
          component: () => import("../views/mobile/information/CompanyAdd.vue")
        },
        {
          path: "information/company/edit/:id?",
          name: "CompanyEdit",
          component: () => import("../views/mobile/information/CompanyEdit.vue")
        },
        {
          path: "information/company/detail/:id?",
          name: "InformationCompanyDetail",
          component: () =>
            import("../views/mobile/information/CompanyDetail.vue")
        },
        {
          path: "information/category",
          name: "InformationCategory",
          component: () => import("../views/mobile/information/Category.vue")
        },
        {
          path: "information/division",
          name: "InformationDivision",
          component: () => import("../views/mobile/information/Division.vue")
        },
        {
          path: "information/contactnumber",
          name: "InformationContact",
          component: () => import("../views/mobile/information/Contact.vue")
        },
        {
          path: "information/calendar",
          name: "InformationCalendar",
          component: () => import("../views/mobile/information/Calendar.vue")
        },

        // Company
        {
          path: "company/managerial",
          name: "CompanyManagerial",
          component: () =>
            import("../views/mobile/company/CompanyManagerial.vue")
        },
        {
          path: "company/category",
          name: "CompanyCategory",
          component: () => import("../views/mobile/company/CompanyCategory.vue")
        },
        {
          path: "company/pettycash",
          name: "CompanyPettyCash",
          component: () =>
            import("../views/mobile/company/CompanyPettyCash.vue")
        },
        {
          path: "company/pettycash/detail/:id?",
          name: "CompanyPettyCashDetail",
          component: () =>
            import("../views/mobile/company/CompanyPettyCashDetail.vue")
        },
        // {
        //   path: "company/pettycash/detail2/:id?",
        //   name: "CompanyPettyCashDetail2",
        //   component: () =>
        //     import("../views/mobile/company/CompanyPettyCashDetail2.vue")
        // },
        // {
        //   path: "company/pettycash/detail3/:id?",
        //   name: "CompanyPettyCashDetail3",
        //   component: () =>
        //     import("../views/mobile/company/CompanyPettyCashDetail3.vue")
        // },

        // Petty Cash
        {
          path: "pettycash",
          name: "PettyCash",
          component: () => import("../views/mobile/pettycash/PettyCash.vue")
        },
        {
          path: "pettycash/detail/:id?",
          name: "PettyCashDetail",
          component: () =>
            import("../views/mobile/pettycash/PettyCashDetail.vue")
        },
        {
          path: "pettycash/topup",
          name: "PettyCashTopup",
          component: () =>
            import("../views/mobile/pettycash/PettyCashTopUp.vue")
        },

        // Announcement
        {
          path: "announcement",
          name: "Announcement",
          component: () =>
            import("../views/mobile/announcement/Announcement.vue")
        },
        {
          path: "announcement/form/:id?",
          name: "AnnouncementForm",
          component: () =>
            import("../views/mobile/announcement/AnnouncementForm.vue")
        },
        {
          path: "announcement/detail/:id?",
          name: "AnnouncementDetail",
          component: () =>
            import("../views/mobile/announcement/AnnouncementDetail.vue")
        },
        

        // Company Bank
        {
          path: "/companybank",
          name: "CompanyBank",
          component: () => import("../views/mobile/companybank/CompanyBank.vue")
        },

        // Notification
        {
          path: "/notifikasi",
          name: "Notifikasi",
          component: () => import("../views/mobile/notifikasi/Notifikasi.vue")
        },

        // Surat Penugasan
        {
          path: "/surattugas",
          name: "SuratTugas",
          component: () => import("../views/mobile/surattugas/SuratTugas.vue")
        },
        {
          path: "/surattugas/create",
          name: "SuratTugasCreate",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasCreate.vue")
        },
        {
          path: "/surattugas/detail/:id?",
          name: "SuratTugasDetail",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasDetail.vue")
        },
        {
          path: "/surattugas/history",
          name: "SuratTugasHistory",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasHistory.vue")
        },
        {
          path: "/surattgs/manage",
          name: "SuratTugasManage",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasManage.vue")
        },

        // Approval Surat Tugas
        {
          path: "/approval/surattugas/:id?",
          name: "ApprovalSuratTugas",
          component: () => import("../views/mobile/approval/ApprSuratTugas.vue")
        },

        // Change Log
        {
          path: "/changelog",
          name: "ChangeLog",
          component: () => import("../views/mobile/changelog/Changelog.vue")
        },

        // Variable
        {
          path: "/set-variable",
          name: "SetVariable",
          meta: { permission: "SET_VARIABLE" },
          component: () => import("../views/mobile/others/SetVariable.vue")
        },
        {
          path: "/set-cuti",
          name: "SetCuti",
          meta: { permission: "SET_CUTI" },
          component: () => import("../views/mobile/others/SetCuti.vue")
        },
        {
          path: "/set-brand",
          name: "SetBrand",
          meta: { permission: "SET_BRAND" },
          component: () => import("../views/mobile/others/SetBrand.vue")
        },
        {
          path: "/set-wip-type",
          name: "SetWIPType",
          meta: { permission: "SET_WIP_TYPE" },
          component: () => import("../views/mobile/others/SetWIPType.vue")
        },
        {
          path: "/set-monthly-type",
          name: "SetMonthlyType",
          meta: { permission: "SET_MONTHLY_TYPE" },
          component: () => import("../views/mobile/others/SetMonthlyType.vue")
        },
        {
          path: "/set-user-type",
          name: "SetUserType",
          meta: { permission: "SET_USER_TYPE" },
          component: () => import("../views/mobile/others/SetUserType.vue")
        },

        //report
        {
          path: "/report/over-production",
          name: "ReportOverProduction",
          meta: { permission: "R_OVER_PRODUCTION" },
          component: () => import("../views/mobile/report/production/OverProduction.vue")
        },
      ]
    }
  ],
  // mode: 'hash',
  mode: "history"
});

router.beforeEach((to, from, next) => {

  // console.log('xxxxxxxxxxxx', from);
  const excludedRoute = ["Login", "formInstanEmployee", "maintenance"];

  // if (to.name !== "Login" && to.name !== "formInstanEmployee") {
  if (!excludedRoute.includes(to.name)) {
    if (localStorage.getItem("user-info") === null) {
      next({ name: "Login" });
      return;
    }

    const api = process.env.VUE_APP_API_URL;

    axios
      .post(
        api + "/v1/check-auth",
        {
          app_v: process.env.VUE_APP_VERSION
        },
        {
          headers: {
            "x-api-key": localStorage.getItem("user-token")
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          let is_maintenance = res.data.data.is_maintenance;
          let is_maintenance_message = res.data.data.is_maintenance_message;
          localStorage.removeItem("is_maintenance_message");
          if(is_maintenance){
            localStorage.setItem("is_maintenance_message", is_maintenance_message);
            next({ name: "maintenance" });
          }

          const permission_key = to.meta.permission;
          if (permission_key != undefined) {
            const userPermissions = JSON.parse(
              localStorage.getItem("user-permissions")
            );

            if (userPermissions.includes(to.meta.permission)) {
              next();
            }else{
              next({ name: "Dashboard" });
            }
          }
          next();
        }
      })
      .catch(error => {
        if (error.response) {
          let code = error.response.status;
          if (code == 400 || code == 500) {
            localStorage.clear();
            next({ name: "Login" });
          }
        }
        next({ name: "Dashboard" });
      });
  } else {
    next();
  }
});

// router.afterEach((to, from) => {
//   console.log('aftereach call');
//   console.log(from);

// })

export default router;
