<template>
  <div class="my-2 mb-3">
    <span v-for="(v, i) in arrDat" :key="'humzzz-' + i" class="mr-1">
      <template v-if="i + 1 != arrDat.length">
        <router-link
          :to="{ name: v.route }"
          class="tnt-main-color this-hover"
          style="font-weight:600"
          >{{ v.text }}</router-link
        >
        <b-icon-chevron-right
          class="active-route"
          style="height: 12px; margin-bottom: 1px"
        />
      </template>
      <template v-else>
        <span class="active-route">{{ v.text }}</span>
      </template>
    </span>
  </div>
</template>


<script>
export default {
  name: "Breadcrumb",

  props: {
    Dashboard: Boolean,
    Attendance: Boolean,
    AttendanceHistory: Boolean,
    AttendancePicture: Boolean,
    AttendanceRequestForm: Boolean,

    Timeoff: Boolean,
    TimeoffForm: Boolean,
    AdminTimeoffManage: Boolean,
    AdminTimeoffCreate: Boolean,

    Reimburse: Boolean,
    ReimburseCreate: Boolean,
    ReimburseEdit: Boolean,

    CashAdvance: Boolean,
    CashAdvanceAdd: Boolean,

    Approval: Boolean,
    ApprovalHistory: Boolean,
    ApprovalDetail: Boolean,

    SuratTugas: Boolean,
    SuratTugasCreate: Boolean,
    SuratTugasDetail: Boolean,
    SuratTugasHistory: Boolean,

    Anggaran: Boolean,
    AnggaranView: Boolean,
    ReportTransfer: Boolean,

    Neraca: Boolean,
    NeracaDetail: Boolean,

    Kasir: Boolean,
    KasirDetail: Boolean,

    FinanceCashAdvance: Boolean,

    FinanceReimburse: Boolean,
    FinanceApprovalReimburse: Boolean,

    PaymentVoucher: Boolean,
    PaymentVoucherDetail: Boolean,

    PettyCash: Boolean,
    PettyCashTopup: Boolean,
    SuratTugasManage: Boolean,

    AttendanceReport: Boolean,
    AttendanceSetting: Boolean,

    Announcement: Boolean,
    AnnouncementCreate: Boolean,
    AnnouncementEdit: Boolean,
    AnnouncementDetail: Boolean,

    Category: Boolean,
    Company: Boolean,
    Division: Boolean,
    CompanyPettyCash: Boolean,
    CompanyPettyCashDetail: Boolean,
    CompanyPettyCashDetail2: Boolean,
    Employee: Boolean,
    EmployeeAdd: Boolean,
    EmployeeEdit: Boolean,
    EmployeeContact: Boolean,

    CompanyBank: Boolean,

    Calendar: Boolean,

    Settings: Boolean,
    SettingProfile: Boolean,
    SettingCompany: Boolean,
    SettingBank: Boolean,
    SettingPassword: Boolean,
    SettingBank3: Boolean,
    SettingPaycheck: Boolean,
    LoginDevices: Boolean,

    ChangeLog: Boolean,

    ProduksiGroup: Boolean,
    Stok: Boolean,
    Waste: Boolean,
    QC: Boolean,
    Setupah: Boolean,
    Mutation: Boolean,
    ProduksiProduksi: Boolean,
    ProduksiProduksiRecap: Boolean,
    ProduksiPlanning: Boolean,

    PayrollView: Boolean,
    PayrollEdit: Boolean,
    PayrollTake: Boolean,

    AngsuranView: Boolean,
    SetVariable: Boolean,

    SetMonthlyType: Boolean,
    SetUserType: Boolean,
  },

  components: {},

  data: () => ({
    arrDat: [],
  }),

  computed: {},

  mounted() {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.Dashboard)
        this.arrDat.push({
          route: "Dashboard",
          text: "Dashboard",
        });

      if (this.Attendance)
        this.arrDat.push({
          route: "Attendance",
          text: "Kehadiran",
        });
      if (this.AttendanceHistory)
        this.arrDat.push({
          route: "AttendanceHistory",
          text: "Riwayat",
        });
      if (this.AttendancePicture)
        this.arrDat.push({
          route: "AttendancePicture",
          text: "Form",
        });
      if (this.AttendanceRequestForm)
        this.arrDat.push({
          route: "AttendanceRequestForm",
          text: "Pengajuan",
        });

      if (this.Onsite)
        this.arrDat.push({
          route: "OnSiteAttendance",
          text: "Kehadiran On Site",
        });

      if (this.Timeoff)
        this.arrDat.push({
          route: "Timeoff",
          text: "Cuti",
        });

      if (this.TimeoffForm)
        this.arrDat.push({
          route: "TimeoffForm",
          text: "Pengajuan Cuti",
        });

      if (this.AdminTimeoffManage)
        this.arrDat.push({
          route: "AdminTimeoffManage",
          text: "Daftar Cuti",
        });

      if (this.AdminTimeoffCreate)
        this.arrDat.push({
          route: "AdminTimeoffCreate",
          text: "Pengajuan Cuti",
        });

      if (this.Reimburse)
        this.arrDat.push({
          route: "Reimburse",
          text: "Reimburse",
        });
      if (this.ReimburseCreate)
        this.arrDat.push({
          route: "Reimburse",
          text: "Buat Reimburse",
        });
      if (this.ReimburseEdit)
        this.arrDat.push({
          route: "Reimburse",
          text: "Edit Reimburse",
        });

      if (this.CashAdvance)
        this.arrDat.push({
          route: "CashAdvance",
          text: "Cash Advance",
        });
      if (this.CashAdvanceAdd)
        this.arrDat.push({
          route: "CashAdvanceAdd",
          text: "Buat Cash Advance",
        });

      if (this.Approval)
        this.arrDat.push({
          route: "Approval",
          text: "Approval",
        });

      if (this.ApprovalHistory)
        this.arrDat.push({
          route: "ApprovalHistory",
          text: "Riwayat",
        });

      if (this.ApprovalDetail)
        this.arrDat.push({
          route: "ApprovalDetail",
          text: "Detail",
        });

      if (this.SuratTugas)
        this.arrDat.push({
          route: "SuratTugas",
          text: "Surat Tugas",
        });
      if (this.SuratTugasCreate)
        this.arrDat.push({
          route: "SuratTugasCreate",
          text: "Buat",
        });
      if (this.SuratTugasDetail)
        this.arrDat.push({
          route: "SuratTugasDetail",
          text: "Detail",
        });
      if (this.SuratTugasHistory)
        this.arrDat.push({
          route: "SuratTugasHistory",
          text: "Riwayat",
        });

      if (this.Anggaran)
        this.arrDat.push({
          route: "Budgeting",
          text: "Anggaran",
        });
      if (this.AnggaranView)
        this.arrDat.push({
          route: "BudgetingEdit",
          text: "Detail",
        });
      if (this.ReportTransfer)
        this.arrDat.push({
          route: "BudgetingReportTransfer",
          text: "Report Transfer",
        });

      if (this.Neraca)
        this.arrDat.push({
          route: "NeracaView",
          text: "Neraca",
        });

      if (this.NeracaDetail)
        this.arrDat.push({
          route: "NeracaDetail",
          text: "Detail",
        });

      if (this.Kasir)
        this.arrDat.push({
          route: "Kasir",
          text: "Kasir Petty Cash",
        });

      if (this.KasirDetail)
        this.arrDat.push({
          route: "KasirApproval",
          text: "Detail",
        });

      if (this.FinanceCashAdvance)
        this.arrDat.push({
          route: "FinanceCashAdvance",
          text: "Finance Cash Advance",
        });

      if (this.FinanceReimburse)
        this.arrDat.push({
          route: "FinanceReimburse",
          text: "Finance Reimburse",
        });

      if (this.FinanceApprovalReimburse)
        this.arrDat.push({
          route: "FinanceApprovalReimburse",
          text: "Detail",
        });

      if (this.PaymentVoucher)
        this.arrDat.push({
          route: "PaymentVoucher",
          text: "Payment Voucher",
        });
      if (this.PaymentVoucherDetail)
        this.arrDat.push({
          route: "PaymentVoucherDetail",
          text: "Detail",
        });

      if (this.PettyCash)
        this.arrDat.push({
          route: "PettyCash",
          text: "Top Up Petty Cash",
        });
      if (this.PettyCashTopup)
        this.arrDat.push({
          route: "PettyCashTopup",
          text: "Pengajuan",
        });

      if (this.SuratTugasManage)
        this.arrDat.push({
          route: "SuratTugasManage",
          text: "Daftar Surat Tugas",
        });

      if (this.AttendanceReport)
        this.arrDat.push({
          route: "AttendanceReport",
          text: "Laporan Kehadiran",
        });

      if (this.AttendanceSetting)
        this.arrDat.push({
          route: "AttendanceSetting",
          text: "Pengaturan Kehadiran",
        });

      if (this.Announcement)
        this.arrDat.push({
          route: "Announcement",
          text: "Pengumuman",
        });
      if (this.AnnouncementCreate)
        this.arrDat.push({
          route: "AnnouncementCreate",
          text: "Buat",
        });
      if (this.AnnouncementEdit)
        this.arrDat.push({
          route: "AnnouncementEdit",
          text: "Edit",
        });
      if (this.AnnouncementDetail)
        this.arrDat.push({
          route: "AnnouncementDetail",
          text: "Detail",
        });

      if (this.Category)
        this.arrDat.push({
          route: "InformationCategory",
          text: "Kategori",
        });
      if (this.Company)
        this.arrDat.push({
          route: "InformationCompany",
          text: "Perusahaan",
        });
      if (this.Division)
        this.arrDat.push({
          route: "InformationDivision",
          text: "Divisi",
        });
      if (this.EmployeeContact)
        this.arrDat.push({
          route: "ContactNumber",
          text: "Kontak Pengguna",
        });
      if (this.Calendar)
        this.arrDat.push({
          route: "Calendar",
          text: "Kalender",
        });

      if (this.CompanyPettyCash)
        this.arrDat.push({
          route: "CompanyPettyCash",
          text: "Pengaturan Petty Cash",
        });
      if (this.CompanyPettyCashDetail)
        this.arrDat.push({
          route: "CompanyPettyCashDetail",
          text: "Petty Cash Detail",
        });
      if (this.CompanyPettyCashDetail2)
        this.arrDat.push({
          route: "CompanyPettyCashDetail2",
          text: "Petty Cash Detail (Besar)",
        });
      if (this.Employee)
        this.arrDat.push({
          route: "Employee",
          text: "Pengguna",
        });
      if (this.EmployeeAdd)
        this.arrDat.push({
          route: "EmployeeAdd",
          text: "Registrasi Pengguna",
        });
      if (this.EmployeeEdit)
        this.arrDat.push({
          route: "EmployeeEdit",
          text: "Ubah Pengguna",
        });

      if (this.CompanyBank)
        this.arrDat.push({
          route: "CompanyBank",
          text: "Bank Perusahaan",
        });

      if (this.Settings)
        this.arrDat.push({
          route: "Settings",
          text: "Akun",
        });

      if (this.SettingProfile)
        this.arrDat.push({
          route: "SettingProfile",
          text: "Profil Pribadi",
        });
      if (this.SettingCompany)
        this.arrDat.push({
          route: "SettingCompany",
          text: "Profil Perusahaan",
        });
      if (this.SettingBank)
        this.arrDat.push({
          route: "SettingBank",
          text: "Rekening Bank Saya",
        });
      if (this.SettingPassword)
        this.arrDat.push({
          route: "SettingPassword",
          text: "Ganti Password",
        });
      if (this.SettingBank3)
        this.arrDat.push({
          route: "SettingBank3",
          text: "Rekening Bank Pihak Ke-3",
        });
      if (this.SettingPaycheck)
        this.arrDat.push({
          route: "SettingPaycheck",
          text: "Slip Gaji",
        });
      if (this.LoginDevices)
        this.arrDat.push({
          route: "LoginDevices",
          text: "Perangkat Aktif",
        });
      if (this.ChangeLog)
        this.arrDat.push({
          route: "ChangeLog",
          text: "Change Log",
        });

      if (this.ProduksiPlanning)
        this.arrDat.push({
          route: "ProduksiPlanning",
          text: "Perencanaan Produksi",
        });

      if (this.ProduksiGroup)
        this.arrDat.push({
          route: "ProduksiGroup",
          text: "Group",
        });

      if (this.Mutation)
        this.arrDat.push({
          route: "EmployeeMutation",
          text: "Perpindahan",
        });

      if (this.Stok)
        this.arrDat.push({
          route: "ProduksiStok",
          text: "WIP",
        });
      if (this.Waste)
        this.arrDat.push({
          route: "ProduksiWaste",
          text: "Waste",
        });
      if (this.QC)
        this.arrDat.push({
          route: "ProduksiQC",
          text: "Quality Control",
        });
      if (this.Setupah)
        this.arrDat.push({
          route: "ProduksiSettingUpah",
          text: "Setting Upah",
        });
      if (this.ProduksiProduksi)
        this.arrDat.push({
          route: "ProduksiProduksi",
          text: "Produksi Harian",
        });
      if (this.ProduksiProduksiRecap)
        this.arrDat.push({
          route: "ProduksiProduksiRecap",
          text: "Produksi Recap",
        });

      if (this.PayrollView)
        this.arrDat.push({
          route: "PayrollView",
          text: "Daftar Upah",
        });
      if (this.PayrollEdit)
        this.arrDat.push({
          route: "PayrollEdit",
          text: "Edit",
        });
      if (this.PayrollTake)
        this.arrDat.push({
          route: "PayrollTake",
          text: "Ambil Upah",
        });

      if (this.AngsuranView)
        this.arrDat.push({
          route: "AngsuranView",
          text: "Angsuran",
        });
      if (this.SetVariable)
        this.arrDat.push({
          route: "SetVariable",
          text: "Set Variable",
        });
      if (this.SetMonthlyType)
        this.arrDat.push({
          route: "SetMonthlyType",
          text: "Set Monthly Type",
        });
      if (this.SetUserType)
        this.arrDat.push({
          route: "SetUserType",
          text: "Set Tipe Pengguna",
        });
    },
  },
};
</script>

<style scoped>
.active-route {
  color: #808080;
  font-weight: 600;
}

.this-hover:hover {
  text-decoration: none;
  color: #006784;
}
</style>