<template>
  <router-view> </router-view>
</template>

<style lang="scss">
// Main Stylesheet
@import "./src/assets/scss/main";

// Bootstrap Vue Stylesheet
@import "~bootstrap-vue/src/index";
</style>

<script>
export default {
  name: "App",

  mounted() {
    document.addEventListener("mouseup", (event) => {
      //get sidebar notif
      if (localStorage.getItem("user-info") != null) {
        this.$axios
          .post("/v1/general/notification/sidebar", {}, this.gl_req_header())
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit("setSbNotif", res.data.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }

      //print dropdown auto dismiss
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }

      //end
    });
  },

  created() {
    setInterval(this.checkCamera, 5000);
  },

  methods: {

  },
};
</script>
