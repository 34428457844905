<template>
  <div id="page-container" :class="classContainer">
    <!-- Page Loader -->
    <!-- <div id="page-loader" :class="{'show': $store.state.settings.pageLoader}"></div> -->
    <!-- END Page Loader -->

    <!-- Page Overlay -->
    <!-- <div id="page-overlay" v-if="$store.state.layout.sideOverlay && $store.state.settings.pageOverlay" @click="() => $store.commit('sideOverlay', { mode: 'close' })"></div> -->
    <!-- END Page Overlay -->

    <!-- Side Overlay -->
    <!-- <base-side-overlay v-if="$store.state.layout.sideOverlay" :class="layoutClasses.sideOverlay"> -->
    <!-- <slot name="side-overlay"></slot> -->
    <!-- </base-side-overlay> -->
    <!-- END Side Overlay -->

    <!-- Sidebar -->
    <base-sidebar
      v-if="$store.state.layout.sidebar"
      :class="layoutClasses.sidebar"
    >
      <slot name="sidebar"></slot>
    </base-sidebar>
    <!-- END Sidebar -->

    <!-- Header -->
    <base-header
      v-if="$store.state.layout.header"
      :class="layoutClasses.header"
    >
      <slot name="header"></slot>
    </base-header>
    <!-- END Header -->

    <!-- Main Container -->
    <div id="main-container">
      <vue-confirm-dialog
        class="custom-vue-confirm-dialog"
      ></vue-confirm-dialog>
      <slot name="content"></slot>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>

      <!-- //button top bottom -->
      <div class="shortcut-top-bottom d-flex flex-column">
        <b-icon-arrow-up-square-fill
          @click="scrollTo('top')"
          class="shortcut-top-bottom-btn mb-1"
          id="shortcut-top-bottom-btn-top"
          style="visibility:hidden;"
        />
        <b-icon-arrow-down-square-fill
          @click="scrollTo('bottom')"
          class="shortcut-top-bottom-btn"
          id="shortcut-top-bottom-btn-bottom"
        />
      </div>
    </div>
    <!-- END Main Container -->

    <!-- Footer -->
    <!-- <base-footer v-if="$store.state.layout.footer" :class="layoutClasses.footer || 'bg-body-light'">
      <slot name="footer"></slot>
    </base-footer> -->
    <!-- END Footer -->
  </div>
</template>

<style lang="scss">
// Custom router view transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.shortcut-top-bottom {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
}

.shortcut-top-bottom-btn {
  cursor: pointer;
  width: 8vw;
  height: 8vw;
  max-height: 40px;
  max-width: 40px;
  background-color: white;
  color: #008eb7;
  border-radius: 5px;
}
</style>

<script>
// Import main layout components
import BaseHeader from "@/layouts/partials/Header";
import BaseSidebar from "@/layouts/partials/Sidebar";
// import BaseSideOverlay from '@/layouts/partials/SideOverlay'
// import BaseFooter from '@/layouts/partials/Footer'

export default {
  name: "BaseLayout",
  components: {
    BaseHeader,
    BaseSidebar
    // BaseSideOverlay,
    // BaseFooter
  },
  props: {
    layoutClasses: Object
  },
  computed: {
    classContainer() {
      return {
        "sidebar-r":
          this.$store.state.layout.sidebar &&
          !this.$store.state.settings.sidebarLeft,
        "sidebar-mini":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarMini,
        "sidebar-o":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarVisibleDesktop,
        "sidebar-o-xs":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarVisibleMobile,
        "sidebar-dark":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarDark,
        "side-overlay-o":
          this.$store.state.layout.sideOverlay &&
          this.$store.state.settings.sideOverlayVisible,
        "side-overlay-hover":
          this.$store.state.layout.sideOverlay &&
          this.$store.state.settings.sideOverlayHoverable,
        "enable-page-overlay":
          this.$store.state.layout.sideOverlay &&
          this.$store.state.settings.pageOverlay,
        "page-header-fixed":
          this.$store.state.layout.header &&
          this.$store.state.settings.headerFixed,
        "page-header-dark":
          this.$store.state.layout.header &&
          this.$store.state.settings.headerDark,
        "main-content-boxed":
          this.$store.state.settings.mainContent === "boxed",
        "main-content-narrow":
          this.$store.state.settings.mainContent === "narrow",
        "rtl-support": this.$store.state.settings.rtlSupport,
        "side-trans-enabled": this.$store.state.settings.sideTransitions,
        "side-scroll": true,
        darkmode: this.$store.state.app.darkmode
      };
    }
  },
  created() {
    // Set default color theme
    this.$store.commit("setColorTheme", {
      theme: this.$store.getters.appColorTheme
    });
  },

  mounted() {
    window.onscroll = function() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      if (winScroll >= height - 200) {
        document.getElementById("shortcut-top-bottom-btn-top").style.visibility = "inherit";
        document.getElementById("shortcut-top-bottom-btn-bottom").style.visibility = "hidden";
      } else if (winScroll > 200 && winScroll < height - 200) {
        document.getElementById("shortcut-top-bottom-btn-top").style.visibility = "inherit";
        document.getElementById("shortcut-top-bottom-btn-bottom").style.visibility = "inherit";
      } else if (winScroll <= 200) {
        document.getElementById("shortcut-top-bottom-btn-top").style.visibility = "hidden";
        document.getElementById("shortcut-top-bottom-btn-bottom").style.visibility = "inherit";
      }
    };
  },

  methods: {
    scrollTo(direction = "top") {
      let ukur = 0; //default top

      if (direction == "bottom") {
        ukur =
          document.body.scrollHeight || document.documentElement.scrollHeight;
      }

      window.scrollTo({ top: ukur, behavior: "smooth" });
    }
  }
};
</script>
